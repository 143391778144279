<template>
    <div>
      <b-modal
        id="processing-attendance-modal"
        centered
        hide-header
        hide-footer
      >
        <div class="header">
          <div class="header-content">
            <p>Processamento de IA</p>
            <Close class="close-svg" @click="closeModal" />
          </div>
        </div>
        <div class="body">
          <LoadingAttendance />
          <p>
            <strong>Nossa IA está processando a sua consulta.</strong>
          </p>
        </div>
  
      </b-modal>
    </div>
  </template>
  <script>
  import { getCurrentClinic } from '@/utils/localStorageManager'
  
  export default {
    name: 'Processing',
    components: {
      Close: () => import('@/assets/icons/close.svg'),
      LoadingAttendance: () => import('@/assets/images/loading-attendance.svg'),
    },
    props: {
      attendance: Object,
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        patientInAttendance: null,
      }
    },
    async mounted() {
      // await this.getRoomsOptions()
    },
    methods: {
      closeModal() {
        this.$bvModal.hide('processing-attendance-modal')
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .is-disabled {
    color: var(--type-placeholder);
    cursor: not-allowed;
  }
  
  .header-content {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--type-active);
  }
  
  .close-svg {
    height: 24px;
    width: 24px;
    fill: var(--neutral-500);
  }
  
  .chevron {
    height: 24px;
    width: 24px;
  }
  
  .clear {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
    padding-right: 30px;
  
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;
  
      &.close {
        fill: var(--type-active);
      }
    }
  }
  
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 600px;
  
    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--type-active);
  
      .header {
        border-bottom: 1px solid var(--neutral-200);
  
        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;
        }
      }
  
      .body {
        text-align: center;
        justify-content: center;
        padding: 24px;
        color: var(--type-active);
        font-size: 16px;
        font-weight: 400;
  
          svg{
            background: 0 0;
            width: 100px;
            height: 100px;
            padding-bottom: 10px;
            float: none;
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
          }
      }
  
      .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        padding: 24px;
  
        button {
          margin-left: 10px !important;
        }
  
        .cancel-button {
          font-weight: bold !important;
          border-color: transparent !important;
        }
      }
    }
  }
  </style>